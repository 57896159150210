import React, { Component } from "react";

import SwiperY from "./swipery";
// import Advantage from "./advantage";
import CompanyPageSlide from "./companypageslide";
import Footer from "./footer";

import { Box, Container } from "@mui/system";
import { Typography } from "@mui/material";
import { styled } from '@mui/material';

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";

const CircleTitle = styled(Box)({
	width: "280px",
	height: "280px",
	backgroundColor: "white",
	// border: "5px solid #4dabcf",
	borderRadius: "50%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
});

const TitleTypo = styled(Typography)({
	fontSize: "20px",
	display: "flex",
	justifyContent: "center",
	// fontWeight: "bold",
	color: "white",
	marginBottom: "20px",
});

const SubTypo = styled(Typography)({
	width: "150px",
	fontSize: "14px",
});

const TaCircleTitle = styled(Box)({
	width: "140px",
	height: "140px",
	backgroundColor: "white",
	// border: "5px solid #4dabcf",
	borderRadius: "50%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
});

const TaTitleTypo = styled(Typography)({
	fontSize: "16px",
	display: "flex",
	justifyContent: "center",
	// fontWeight: "bold",
	color: "white",
	marginBottom: "10px",
});

const TaSubTypo = styled(Typography)({
	width: "100px",
	fontSize: "11px",
});

export default class CompanyPage extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
		};
	}

	componentDidMount()
	{
		AOS.init();
	}

	render()
	{
		return (
			<>
				<PC>
					<Box>
						<SwiperY>
							<Box sx={{ height: "100vh", backgroundColor: "black", paddingTop: "150px" }}>
								<Container>
									<Box width="100%" mb={20}>
										<Typography data-aos="fade-up" data-aos-delay="500" variant="h2" mb={1} sx={{ color: "#ffffff", fontWeight: "bold" }}>BioBrain Values</Typography>
										<Typography data-aos="fade-up" data-aos-delay="600" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 생체계측과 AI기술을 보유한 혁신적인 기업으로, 정밀한 <span style={{ color: "#00a0b9" }}>생체신호 측정</span>과 뛰어난 <span style={{ color: "#00a0b9" }}>AI인력풀</span>로</Typography>
										<Typography data-aos="fade-up" data-aos-delay="700" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>최적의 <span style={{ color: "#00a0b9" }}>데이터 분석을 통한 맞춤형 서비스</span>를 제공하며, 산업을 선도하는</Typography>
										<Typography data-aos="fade-up" data-aos-delay="800" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>선진 기술과 높은 잠재력을 가지고 있습니다.</Typography>
									</Box>
									<Box width="100%" display="flex" justifyContent="center">
										<CircleTitle m={1} data-aos="zoom-in" data-aos-delay="750" data-aos-duration="1200" sx={{ backgroundColor: "#d6e2f0" }}>
											<Box display="flex" flexDirection="column">
												<TitleTypo sx={{ color: "black" }}>
													우수 인력
												</TitleTypo>
												<SubTypo sx={{ width: "160px" }}>다년간 프로젝트 경험을 보요한 품질 관리자</SubTypo>
											</Box>
										</CircleTitle>
										<CircleTitle m={1} data-aos="zoom-in" data-aos-delay="950" data-aos-duration="1200" sx={{ backgroundColor: "#728bb4" }}>
											<Box display="flex" flexDirection="column">
												<TitleTypo>
													맞춤 협업
												</TitleTypo>
												<SubTypo sx={{ width: "160px", color: "white" }}>KISTI의 정신분석솔루션 사업에 참여한 경험과 웹 구축 경험</SubTypo>
											</Box>
										</CircleTitle>
										<CircleTitle m={1} data-aos="zoom-in" data-aos-delay="850" data-aos-duration="1200" sx={{ backgroundColor: "#74b3ec" }}>
											<Box display="flex" flexDirection="column">
												<TitleTypo>
													경험 활용
												</TitleTypo>
												<SubTypo sx={{ width: "160px", color: "white" }}>다양한 협업 경험, 기관별 고유한 요구사항에 대하여 맞춤 협업을 진행 </SubTypo>
											</Box>
										</CircleTitle>
										<CircleTitle m={1} data-aos="zoom-in" data-aos-delay="1050" data-aos-duration="1200" sx={{ backgroundColor: "#4b9bb2" }}>
											<Box display="flex" flexDirection="column">
												<TitleTypo>
													개발 이력
												</TitleTypo>
												<SubTypo sx={{ width: "160px", color: "white" }}>뇌전도, 심전도, 근전도 등 헬스케어 데이터 기반의 시스템, 웹 개발 이력</SubTypo>
											</Box>
										</CircleTitle>
									</Box>
								</Container>
							</Box>
							<CompanyPageSlide />
							<Footer />
						</SwiperY >
					</Box>
				</PC>
				<TABLET>
					<Box width="100%" height="100%" sx={{ backgroundColor: "black", paddingTop: "150px" }}>
						<Container>
							<Box m={1} >
								<Typography display="flex" justifyContent="center" data-aos="fade-up" data-aos-delay="500" variant="h3" mb={5} sx={{ color: "#ffffff", fontWeight: "bold" }}>BioBrain Values</Typography>
								<Typography data-aos="fade-up" data-aos-delay="500" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 생체계측과 AI기술을 보유한 혁신적인 기업으로, 정밀한</Typography>
								<Typography data-aos="fade-up" data-aos-delay="500" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>  <span style={{ color: "#00a0b9" }}>생체신호 측정</span>과 뛰어난 <span style={{ color: "#00a0b9" }}>AI인력풀</span>, 최적의 <span style={{ color: "#00a0b9" }}>데이터 분석을 통한 맞춤형 서비스</span>를 제공하며, 산업을 선도하는 선진 기술과 높은 잠재력을 가지고 있습니다.</Typography>
								<Typography data-aos="fade-up" data-aos-delay="500" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>최적의 <span style={{ color: "#00a0b9" }}>데이터 분석을 통한 맞춤형 서비스</span>를 제공하며, 산업을 선도하는 선진 기술과 높은 잠재력을 가지고 있습니다.</Typography>
							</Box>
							<Box my={15} display="flex" justifyContent="center">
								<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="750" data-aos-duration="1200" sx={{ backgroundColor: "#d6e2f0" }}>
									<Box display="flex" flexDirection="column">
										<TaTitleTypo sx={{ color: "black" }}>
											우수 인력
										</TaTitleTypo>
										<TaSubTypo>다년간 프로젝트 경험을 보요한 품질 관리자</TaSubTypo>
									</Box>
								</TaCircleTitle>
								<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="950" data-aos-duration="1200" sx={{ backgroundColor: "#728bb4" }}>
									<Box display="flex" flexDirection="column">
										<TaTitleTypo>
											맞춤 협업
										</TaTitleTypo>
										<TaSubTypo sx={{ color: "white" }}>KISTI의 정신분석솔루션 사업에 참여한 경험과 웹 구축 경험</TaSubTypo>
									</Box>
								</TaCircleTitle>
								<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="850" data-aos-duration="1200" sx={{ backgroundColor: "#74b3ec" }}>
									<Box display="flex" flexDirection="column">
										<TaTitleTypo>
											경험 활용
										</TaTitleTypo>
										<TaSubTypo sx={{ color: "white" }}>다양한 협업 경험, 기관별 고유한 요구사항에 대하여 맞춤 협업을 진행 </TaSubTypo>
									</Box>
								</TaCircleTitle>
								<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="1050" data-aos-duration="1200" sx={{ backgroundColor: "#4b9bb2" }}>
									<Box display="flex" flexDirection="column">
										<TaTitleTypo>
											개발 이력
										</TaTitleTypo>
										<TaSubTypo sx={{ color: "white" }}>뇌전도, 심전도, 근전도 등 헬스케어 데이터 기반의 시스템, 웹 개발 이력</TaSubTypo>
									</Box>
								</TaCircleTitle>
							</Box>
						</Container>
					</Box>
					<Box marginBottom="100px">
						<CompanyPageSlide />
					</Box>
					<Footer />
				</TABLET>
				<MOBILE>
					<Box sx={{ width: "100%", height: "100%", backgroundColor: "black" }}>
						<SwiperY>
							<Box>
								<Box>
									<Container sx={{ width: "100%", height: "100%", backgroundColor: "black", paddingTop: "100px" }}>
										<Box >
											<Typography display="flex" justifyContent="center" data-aos="fade-up" data-aos-delay="500" variant="h4" mb={1} sx={{ color: "#ffffff", fontWeight: "bold" }}>BioBrain Values</Typography>
											<Typography data-aos="fade-up" data-aos-delay="600" variant="body2" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 생체계측과 AI기술을 보유한 혁신적인 기업으로, 정밀한 <span style={{ color: "#00a0b9" }}>생체신호 측정</span>과 뛰어난 <span style={{ color: "#00a0b9" }}>AI인력풀</span>로</Typography>
											<Typography data-aos="fade-up" data-aos-delay="700" variant="body2" sx={{ color: "#ffffff", fontWeight: "bold" }}>최적의 <span style={{ color: "#00a0b9" }}>데이터 분석을 통한 맞춤형 서비스</span>를 제공하며, 산업을 선도하는 선진 기술과 높은 잠재력을 가지고 있습니다.</Typography>
											{/* <Typography data-aos="fade-up" data-aos-delay="800" variant="body2" sx={{ color: "#ffffff", fontWeight: "bold" }}>선진 기술과 높은 잠재력을 가지고 있습니다.</Typography> */}
										</Box>
										<Box m={10} display="flex" justifyContent="center">
											<Box>
												<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="750" data-aos-duration="1200" sx={{ backgroundColor: "#d6e2f0" }}>
													<Box display="flex" flexDirection="column">

														<TaTitleTypo sx={{ color: "black" }}>
															우수 인력
														</TaTitleTypo>
														<TaSubTypo>다년간 프로젝트 경험을 보요한 품질 관리자</TaSubTypo>
													</Box>
												</TaCircleTitle>
												<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="950" data-aos-duration="1200" sx={{ backgroundColor: "#728bb4" }}>
													<Box display="flex" flexDirection="column">
														<TaTitleTypo>
															맞춤 협업
														</TaTitleTypo>
														<TaSubTypo sx={{ color: "white" }}>KISTI의 정신분석솔루션 사업에 참여한 경험과 웹 구축 경험</TaSubTypo>
													</Box>
												</TaCircleTitle>
											</Box>
											<Box>
												<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="850" data-aos-duration="1200" sx={{ backgroundColor: "#74b3ec" }}>
													<Box display="flex" flexDirection="column">
														<TaTitleTypo>
															경험 활용
														</TaTitleTypo>
														<TaSubTypo sx={{ color: "white" }}>다양한 협업 경험, 기관별 고유한 요구사항에 대하여 맞춤 협업을 진행 </TaSubTypo>
													</Box>
												</TaCircleTitle>
												<TaCircleTitle m={1} data-aos="zoom-in" data-aos-delay="1050" data-aos-duration="1200" sx={{ backgroundColor: "#4b9bb2" }}>
													<Box display="flex" flexDirection="column">
														<TaTitleTypo>
															개발 이력
														</TaTitleTypo>
														<TaSubTypo sx={{ color: "white" }}>뇌전도, 심전도, 근전도 등 헬스케어 데이터 기반의 시스템, 웹 개발 이력</TaSubTypo>
													</Box>
												</TaCircleTitle>
											</Box>
										</Box>
									</Container>
								</Box>
							</Box>
							<Box sx={{ height: "100%" }}>
								<CompanyPageSlide />
							</Box>
							<Footer />
						</SwiperY >
					</Box>
				</MOBILE>
			</>
		)
	}
};