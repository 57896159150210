import React, { Component } from "react";
import "../research.css";

import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { styled } from '@mui/material/styles';
// 의사 소통 아이콘  제작자: Andy Horvath - Flaticon
// 골 아이콘  제작자: Becris - Flaticon
// 혁신 아이콘  제작자: Freepik - Flaticon

import AOS from "aos";
import "aos/dist/aos.css";
import { MOBILE, TABLET, PC } from "./global";

const JoinBox = styled(Box)({
	width: "250px",
	height: "300px",
	justifyContent: "center",
	// backgroundColor: "white",
	margin: "20px",
	borderRadius: "10px",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	backgroundColor: "black",
});


const JoinSubTitle = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	fontSize: "20px",
	fontWeight: "bold",
	marginTop: 30,
	color: "white",
});


const JoinText = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	fontSize: "16px",
	// fontWeight:"bold",
	margin: 20,
	color: "white",
});

const JoinImageBox = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-end",
	height: "70px",
	// marginTop:"20px"
});

const JoinTaBox = styled(Box)({
	width: "250px",
	height: "250px",
	justifyContent: "center",
	// backgroundColor: "white",
	margin: "20px",
	borderRadius: "10px",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	backgroundColor: "black",
});


const JoinTaTitle = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	fontSize: "18px",
	fontWeight: "bold",
	paddingTop: 15,
	color: "white",
});

const JoinTaText = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	fontSize: "14px",
	width: "90%",
	margin: 10,
	color: "white",
});

const JoinMobileBox = styled(Box)({
	width: "300px",
	height: "150px",
	justifyContent: "center",
	margin: 3,
	// padding: 1,
	borderRadius: "10px",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	backgroundColor: "black",
});

const JoinMoTitle = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	fontSize: "18px",
	fontWeight: "bold",
	paddingTop: 20,
	color: "white",
});

const JoinMoText = styled(Typography)({
	// display: "flex",
	// justifyContent: "center",
	fontSize: "12px",
	width: "70%",
	margin: 10,
	color: "white",
});

export default class Company extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			showContent: false,
		};
	}

	componentDidMount()
	{
		AOS.init();
		window.addEventListener('beforeunload', this.handlePageUnload);
	}

	componentWillUnmount()
	{
		window.removeEventListener('beforeunload', this.handlePageUnload);
	}

	handlePageUnload = () =>
	{
		this.setState({ showContent: false });
	};

	toggleContent = () =>
	{
		this.setState({ showContent: !this.state.showContent });
	};

	render()
	{
		return (
			<>
				<PC>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100vh", backgroundColor: "black" }}>
						<Box sx={{ backgroundColor: "black" }}>
							<Container sx={{ width: "100%", height: "100vh", backgroundColor: "black", paddingTop: "200px" }}>
								<Box mb={10} display="flex" justifyContent="space-between" sx={{ backgroundColor: "black" }}>
									<Box pt={10}>
										<Typography data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}> <span style={{ color: "#00a0b9" }}>Join</span> us</Typography>
										<Typography data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>함께 나아갈 인재를 기다립니다.
										</Typography>
									</Box>
								</Box>
								<Box justifyContent="column" sx={{ width: "100%", paddingBottom: "100px" }} >
									<Box display="flex">
										<JoinBox data-aos="zoom-up" data-aos-delay="650" data-aos-duration="950">
											<JoinSubTitle>창의적 문제 해결사</JoinSubTitle>
											<JoinText>"상상력이 현실이 되는 곳에서, 당신의 창의적인 아이디어가 혁신을 이끌고 새로운 가능성을 탐색합니다."</JoinText>
											<JoinImageBox mt={5} display="flex" justifyContent="center" alignItems="center">
												<img src={process.env.PUBLIC_URL + '/assets/images/creativity.png'} alt="골 아이콘  제작자: Becris" style={{ width: "70px", height: "70px", backgroundColor: "white" }} />
											</JoinImageBox>
										</JoinBox>
										<JoinBox data-aos="zoom-up" data-aos-delay="750" data-aos-duration="950" >
											<JoinSubTitle>도전적인 탐험가</JoinSubTitle>
											<JoinText>"한계는 없습니다, 오직 도전만이 있죠. 불가능을 가능으로 바꾸는 당신의 용기와 열정이 우리 팀의 힘이 됩니다."</JoinText>
											<JoinImageBox mt={5} display="flex" justifyContent="center" alignItems="center">
												<img src={process.env.PUBLIC_URL + '/assets/images/success.png'} alt="골 아이콘  제작자: Becris" style={{ width: "70px", height: "70px", backgroundColor: "white" }} />
											</JoinImageBox>
										</JoinBox>
										<JoinBox data-aos="zoom-up" data-aos-delay="850" data-aos-duration="950" >
											<JoinSubTitle>소통의 달인</JoinSubTitle>
											<JoinText>"함께라면 더 높이 날 수 있습니다. 당신의 따뜻한 소통과 협력이 우리 팀의 에너지를 더욱 강하게 만들어줄 거예요."</JoinText>
											<JoinImageBox mt={3} display="flex" justifyContent="center" alignItems="center">
												<img src={process.env.PUBLIC_URL + '/assets/images/discussion.png'} alt="의사 소통 아이콘  제작자: Andy Horvath" style={{ width: "70px", height: "70px", backgroundColor: "white" }} />
											</JoinImageBox>
										</JoinBox>
									</Box>
								</Box>
							</Container>
						</Box>
					</Box>
				</PC>
				<TABLET>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%", backgroundColor: "black", paddingY:"150px" }}>
						<Container sx={{ width: "100%", height: "100%", backgroundColor: "black"}}>
							<Box display="flex" justifyContent="space-between" sx={{ backgroundColor: "black" }}>
								<Box padding={3}>
									<Typography data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}><span style={{ color: "#00a0b9" }}>Join</span> us</Typography>
									<Typography data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>함께 나아갈 인재를 기다립니다.
									</Typography>
								</Box>
							</Box>
							<Box paddingTop={10} justifyContent="column" sx={{ width: "100%" }} >
								<Box display="flex">
									<JoinTaBox data-aos="zoom-up" data-aos-delay="650" data-aos-duration="950">
										<JoinTaTitle>창의적 문제 해결사</JoinTaTitle>
										<JoinTaText>"상상력이 현실이 되는 곳에서, 당신의 창의적인 아이디어가 혁신을 이끌고 새로운 가능성을 탐색합니다."</JoinTaText>
										<JoinImageBox display="flex" justifyContent="center" alignItems="center">
											<img src={process.env.PUBLIC_URL + '/assets/images/creativity.png'} alt="골 아이콘  제작자: Becris" style={{ width: "50px", height: "50px", backgroundColor: "white" }} />
										</JoinImageBox>
									</JoinTaBox>
									<JoinTaBox data-aos="zoom-up" data-aos-delay="750" data-aos-duration="950" >
										<JoinTaTitle>도전적인 탐험가</JoinTaTitle>
										<JoinTaText>"한계는 없습니다, 오직 도전만이 있죠. 불가능을 가능으로 바꾸는 당신의 용기와 열정이 우리 팀의 힘이 됩니다."</JoinTaText>
										<JoinImageBox display="flex" justifyContent="center" alignItems="center">
											<img src={process.env.PUBLIC_URL + '/assets/images/success.png'} alt="골 아이콘  제작자: Becris" style={{ width: "50px", height: "50px", backgroundColor: "white" }} />
										</JoinImageBox>
									</JoinTaBox>
									<JoinTaBox data-aos="zoom-up" data-aos-delay="850" data-aos-duration="950" >
										<JoinTaTitle>소통의 달인</JoinTaTitle>
										<JoinTaText>"함께라면 더 높이 날 수 있습니다. 당신의 따뜻한 소통과 협력이 우리 팀의 에너지를 더욱 강하게 만들어줄 거예요."</JoinTaText>
										<JoinImageBox display="flex" justifyContent="center" alignItems="center">
											<img src={process.env.PUBLIC_URL + '/assets/images/discussion.png'} alt="의사 소통 아이콘  제작자: Andy Horvath" style={{ width: "50px", height: "50px", backgroundColor: "white" }} />
										</JoinImageBox>
									</JoinTaBox>
								</Box>
							</Box>
						</Container>
					</Box>
				</TABLET>
				<MOBILE>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%", backgroundColor: "black"}}>
						<Container sx={{ width: "100%", backgroundColor: "black", paddingTop: "10px" }}>
							<Box mt={2} justifyContent="column" alignItems="center">
								<Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}><span style={{ color: "#00a0b9" }}>Join</span> us</Typography>
								<Typography mb={2} variant="body2" sx={{ fontWeight: "bold", color: "white" }}>함께 나아갈 인재를 기다립니다.</Typography>
								<Box display="flex" justifyContent="center" sx={{ width: "100%" }} data-aos="zoom-in" data-aos-delay="700" data-aos-duration="950">
									<Box display="column">
										<JoinMobileBox>
											<JoinMoTitle>창의적 문제 해결사</JoinMoTitle>
											<Box display="flex" justifyContent="center" alignItems="center">
												<JoinMoText>"상상력이 현실이 되는 곳에서, 당신의 창의적인 아이디어가 혁신을 이끌고 새로운 가능성을 탐색합니다."</JoinMoText>
												<Box component="img" src={process.env.PUBLIC_URL + '/assets/images/creativity.png'} sx={{ width: "40px", height: "40px", backgroundColor: "white" }} />
											</Box>
										</JoinMobileBox>
										<JoinMobileBox>
											<JoinMoTitle>도전적인 탐험가</JoinMoTitle>
											<Box display="flex" justifyContent="center" alignItems="center">
												<JoinMoText>"한계는 없습니다, 오직 도전만이 있죠. 불가능을 가능으로 바꾸는 당신의 용기와 열정이 우리 팀의 힘이 됩니다."</JoinMoText>
												<Box component="img" src={process.env.PUBLIC_URL + '/assets/images/success.png'} alt="골 아이콘  제작자: Becris" sx={{ width: "40px", height: "40px", backgroundColor: "white" }} />
											</Box>
										</JoinMobileBox>
										<JoinMobileBox>
											<JoinMoTitle>소통의 달인</JoinMoTitle>
											<Box display="flex" justifyContent="center" alignItems="center">
												<JoinMoText>"함께라면 더 높이 날 수 있습니다. 당신의 따뜻한 소통과 협력이 우리 팀의 에너지를 더욱 강하게 만들어줄 거예요."</JoinMoText>
												<Box display="flex" justifyContent="center" alignItems="center">
													<Box component="img" src={process.env.PUBLIC_URL + '/assets/images/discussion.png'} alt="의사 소통 아이콘  제작자: Andy Horvath" sx={{ width: "40px", height: "40px", backgroundColor: "white" }} />
												</Box>
											</Box>
										</JoinMobileBox>
									</Box>
								</Box>
							</Box>
						</Container>
					</Box >
				</MOBILE >
			</>
		)
	}
}
