import React, { Component } from "react";

import "../companypageslide.css";

import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import AOS from "aos";
import "aos/dist/aos.css";
import { MOBILE, TABLET, PC } from "./global";

import { Navigation } from "swiper";

export default class CompanyPageSlide extends Component
{
	componentDidMount()
	{
		AOS.init();
	}

	toggleExpanded = () =>
	{
		this.setState(prevState => ({
			expanded: !prevState.expanded
		}));
	};

	handleInteraction = () =>
	{
		this.toggleExpanded();
	};

	render()
	{
		const historydata = [
			{
				year: 2023,
				content:
					"• 반도체장비 상태측정기반 분석 플랫폼\n •Web 기반 그림심리 평가 도구\n • 열화상 데이터 전처리를 위한 DICOM이미지 편집 도구\n • MRI DICOM 파일기반 영상 연산 플랫폼"
			},
			{
				year: 2022,
				content:
					" • BIOS-MI & KUBIOS Cloud HRV분석 플랫폼 연동\n • 운동측정및 분석 웹 플랫폼\n • 영상AI 기반 자동경혈점 위치인식 시스템"
			},
			{
				year: 2021,
				content:
					"• AI Hub에 인공지능 모델 공급업체 등록\n • CJ-서울시 오벤터스 본선참가\n • 원통형 MRI 경사자계 코일 설계\n • 핸즈온 및 생체계측 모듈"
			},
			{
				year: 2020,
				content:
					"01 무선 건식 8채널 뇌파측정시스템을 국제공동개발\n 04 인공지능모델링을 위한 데이터공급 및 가공 \n기업등록(과기정통부)\n 05 데이터스토어 입점\n 07 신항원 예측서비스 플랫폼 제작 (KISTI)\n 09 국제인공지능대전 참가\n 11 Web board capable of replay and server data storage (KAIST Touch Math)"
			},
			{
				year: 2019,
				content: "01 Stress measurement and care program production: ETRIOXYSPA\n 03 Spine scan chair algorithm development\n 05 인공수분 자율비행 드론 개발: 인공지능협회\n 07 화자구분 음성인식 녹음 프로그램개발 : 씨크릿우먼\n 09 자동 의복 재단치수 소프트웨어: 충남대학교 의류학과\n 11 다원수면상태 센싱 시스템: SAFAUD"
			},
			{
				year: 2018,
				content: "02 하이퍼스캔 뇌전도 계측시스템 개발: KAERI\n 03 단말기 임베디드형 하이퍼스캔 개발\n 04 정신건강 모니터링 시스템 개발: KISTI\n 06 생물학실험을 위한 LED 기반의 광자극기 제작: \n조선대\n 10 송장인식 인공지능 모델 및 알고리즘 개발: 제스엠텍"
			},
			{
				year: 2017,
				content: "01 다채널 다원생체신호 계측 시스템 개발 및 제조 판매\n 03 저채널 무선생체신호 계측 시스템 개발 및 제조 판매\n 06 무선 근전도 및 심전도 시스템\n 08 초소형 다원생체신호 계측 모듈 \n솔루션 개발 및 제조판매\n 10 마취심도지표 BIS의 하부 모수들을 추정 및 산출"
			},
			{
				year: 2016,
				content: "03 폴리그래프 BPG9공급: 국립원예특작연구원\n05 무선 근전도 WBMG8 공급: 대전대학교 뷰티학과\n 07 뇌파기반 감성매칭 시스템 공급: 결혼정보회사 \n바로연 인천지사\n 08 Mathematica 기반 신호분석 패키지: KAIST 미래전략대학원\n 10 무선근전도 WBMG8 공급: 진주대학교 체육교육과"
			},
			{
				year: 2015,
				content: "02 뉴로피드백 시스템 개발공급: 우송고등학교\n 03 뇌파기반 감성매칭 시스템 공급: 결혼정보회사 \n바로연 수원지사\n 05 대덕과학연구단지내 첨단기술유통빌딩 입주\n 08 보행피드백장치 개발공급: 대전대학교 물리치료학과\n 09 Mathematica 기반 신호 분석패키지: ETRI 의료영상연구부\n 10 다원생체신호 (EEG+ECG+EMG) 측정장비 개발 및 제조 판매\n 11 폴리그래프 BPG9 공급: 충남대학교 의류학과"
			},
			{
				year: 2014,
				content: "05 3채널심전도 측정 및 심박변이도 분석기 개발\n 07 뇌파분석 알고리즘 및 분석소프트웨어 개발공급: \n(주)라이프사이언스테크놀로지\n 10 2채널 범용 생체신호측정기 NeuroCraft 개발공급:\nKAIST 바이오 및 뇌공학과"
			},
			{
				year: 2013,
				content: "02 (주)바이오브레인 법인 설립\n 04 뇌파측정용 EEG Hardware 설계 및 개발\n 07 2채널 범용 생체 신호 측정기 NeuroCraft 개발공급\n 09 Target image extension algorithm for SURF \nbasesd object tracking program\n 10 인간공학연구용 KNMI 통합측정 및 분석 시스템 개발공급\n 11 폴리그래프+FES 율합 시스템 개발공급"
			}
		]

		return (
			<>
				<PC>
					<Box>
						{/* 연혁 */}
						<Box sx={{ height: "100%", backgroundColor: "black", paddingTop: "150px" }}>
							<Box width="100%" >
								<Box mb={15} mr={5} display="column">
									<Typography variant="h2" display="flex" justifyContent="flex-end" sx={{ color: "#ffffff", fontWeight: "bold" }}>SINCE 2013</Typography>
									<Typography data-aos="fade-up" data-aos-delay="500" display="flex" justifyContent="flex-end" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 <span style={{ color: "#00a0b9" }}> &nbsp;생체계측과 AI분야</span>에서 꾸준한 연구 개발 노력과 혁신적인 솔루션으로,</Typography>
									<Typography data-aos="fade-up" data-aos-delay="600" display="flex" justifyContent="flex-end" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>국내외에서 다양한 산업 및 분야에서 다양한 기술적 도약을 이루어왔습니다.</Typography>
								</Box>
							</Box>
							<Box>
								<Swiper
									slidesPerView={3}
									spaceBetween={3}
									navigation={true}
									modules={[Navigation]}
									margin={0}
								>
									{
										historydata.map((e, i) =>
										{
											return (
												<SwiperSlide>
													<Box height="300px">
														<Typography p={2} variant="h2" display="flex" justifyContent="flex-end" fontWeight="500">{e.year}</Typography>
														<Box sx={{ width: "100%", height: "3px", backgroundColor: "#4dabcf" }}></Box>
														<Box mt={5} display="flex" justifyContent="center" alignItems="center" >
															<Box>
																<Typography variant="body1" sx={{ color: "gray", fontWeight: "bold", whiteSpace: "pre-line" }}>{e.content}</Typography>
															</Box>
														</Box>
													</Box>
												</SwiperSlide>
											);
										})
									}
								</Swiper>
							</Box>
						</Box>
					</Box>
				</PC>
				<TABLET>
					<Box>
						{/* 연혁 */}
						<Box sx={{width:"100%", height: "100%", backgroundColor: "black"}}>
							<Box paddingBottom={10}>
								<Box p={2} display="column">
									<Typography mb={2} variant="h3" display="flex" justifyContent="flex-end" sx={{ color: "#ffffff", fontWeight: "bold" }}>SINCE 2013</Typography>
									<Typography data-aos="fade-up" data-aos-delay="500" display="flex" justifyContent="flex-end" variant="h6" sx={{ width:"100%", color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 <span style={{ color: "#00a0b9" }}> 생체계측</span>과 &nbsp; <span style={{ color: "#00a0b9" }}>AI분야</span>에서 꾸준한 연구 개발 노력과 혁신적인 </Typography>
									<Typography data-aos="fade-up" data-aos-delay="500" display="flex" justifyContent="flex-end" variant="h6" sx={{ width:"100%", color: "#ffffff", fontWeight: "bold" }}> 솔루션으로, 국내외에서 다양한 산업 및 분야에서</Typography>
									<Typography data-aos="fade-up" data-aos-delay="500" display="flex" justifyContent="flex-end" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>다양한 기술적 도약을 이루어왔습니다.</Typography>
								</Box>
							</Box>
							<Box sx={{ paddingTop: "50px", paddingButtom: "50px", backgroundColor: "black", }}>
								<Swiper
									slidesPerView={2}
									spaceBetween={5}
									navigation={false}
									modules={[Navigation]}
									margin={0}
									className="mobileswiper"
								>
									{
										historydata.map((e, i) =>
										{
											return (
												<SwiperSlide>
													<Box>
														<Typography p={1} variant="h3" display="flex" justifyContent="flex-end" sx={{ border: "2px solid white" }}>{e.year}</Typography>
														<Box sx={{ width: "100%", height: "3px", backgroundColor: "#4dabcf" }}></Box>
														<Box mt={3} display="flex" justifyContent="center" alignItems="center" >
															<Box>
																<Typography variant="body1" sx={{ color: "gray", fontWeight: "bold", whiteSpace: "pre-line" }}>{e.content}</Typography>
															</Box>
														</Box>
													</Box>
												</SwiperSlide>
											);
										})
									}
								</Swiper>
							</Box>
						</Box>
					</Box>
				</TABLET>
				<MOBILE>
					<Box sx={{ height: "100%", paddingTop: "100px" }}>
						{/* 연혁 */}
						<Box sx={{ backgroundColor: "black" }}>
							<Box width="100%" >
								<Box p={2} display="column">
									<Typography pr={1} variant="h4" display="flex" justifyContent="flex-end" sx={{ color: "#ffffff", fontWeight: "bold" }}>SINCE 2013</Typography>
									<Typography data-aos="fade-up" data-aos-delay="500" sx={{ fontSize: "14px", color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 &nbsp;<span style={{ color: "#00a0b9" }}>생체계측</span>과&nbsp; <span style={{ color: "#00a0b9" }}>AI분야</span>에서 꾸준한 연구 개발노력과  혁신적인 솔루션으로, 국내 외에서 다양한산업 및 분야에서 산업 및 분야에서 다양한 기술적 도약을 이루어왔습니다.</Typography>
								</Box>
							</Box>
							<Box sx={{ paddingTop: "20px", backgroundColor: "black", }}>
								<Swiper
									slidesPerView={1}
									spaceBetween={3}
									navigation={false}
									modules={[Navigation]}
									margin={0}
									className="mobileswiper"
								>
									{
										historydata.map((e, i) =>
										{
											return (
												<SwiperSlide>
													<Box >
														<Typography p={1} variant="h3" display="flex" justifyContent="flex-end" sx={{ border: "2px solid white" }}>{e.year}</Typography>
														<Box sx={{ width: "100%", height: "3px", backgroundColor: "#4dabcf" }}></Box>
														<Box mt={1} display="flex" justifyContent="center" alignItems="center" >
															<Box>
																<Typography variant="body1" sx={{ color: "gray", fontWeight: "bold", whiteSpace: "pre-line" }}>{e.content}</Typography>
															</Box>
														</Box>
													</Box>
												</SwiperSlide>
											);
										})
									}
								</Swiper>
							</Box>
						</Box>
					</Box>
				</MOBILE>
			</>
		);
	}
};
