import { useMediaQuery } from "react-responsive";

export const MOBILE = ({ children }) =>
{
	const isMobile = useMediaQuery({
		query: "(max-width:649px)"
	});
	return <>{isMobile && children}</>
}

export const TABLET = ({ children }) =>
{
	const isTablet = useMediaQuery({
		query: "(min-width:650px) and (max-width:1023px)"
	});
	return <>{isTablet && children}</>
}

export const PC = ({ children }) =>
{
	const isPc = useMediaQuery({
		query: "(min-width:1023px)"
	});
	return <>{isPc && children}</>
}

export const DEFAULT_DELAY = 500;