import React, { Component } from 'react';
import { Box, Typography, Button, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";
import Footer from './footer';

const TabBox = styled(Button)({
	width: "300px",
	height: "100px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	margin: "20px",
	textTransform: "none",
	backgroundColor: "black",
	color: "white",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
});

const VideoTabBox = styled(Button)(({ bgImage }) => ({
	width: "330px",
	height: "250px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	margin: "20px",
	textTransform: "none",
	backgroundColor: "black",
	color: "white",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	backgroundImage: `url(${bgImage})`, // Using dynamic background image
	backgroundSize: "cover",
	backgroundPosition: "center",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
}));

const MobileTabBox = styled(Button)({
	width: "200px",
	height: "100px",
	margin: 5,
	backgroundColor: "black",
	color: "white",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
});

class Ai extends Component
{
	constructor(props)
	{
		super(props);
		this.state = { isHovered: false };
	}

	componentDidMount()
	{
		AOS.init();
	}

	handleDialogOpen = (url) =>
	{
		this.setState({ dialogOpen: true, videoUrl: url });
	}

	handleDialogClose = () =>
	{
		this.setState({ dialogOpen: false, videoUrl: "" });
	}

	render()
	{
		const tabs = [
			{ title: "바이오브레인 언어지능 - 아수(我誰)", url: "http://ai.biobrain.kr/" },
			{ title: "바이오브레인 시각언어지능 멀티도메인지능 - 드림캐쳐(Dreamcatcher)", url: "http://ai.biobrain.kr/dreamcatcher" },
			{ title: "글(契)을 그셔(鍥) 다시 봐요 \n (Web-Note)", url: "https://biobrain.kr/web-note/" },
			{ title: "얼굴 감성 인식", url: "https://biobrain.kr/faceapi/" },
			{ title: "얼굴 인식 ( 안중근, 유관순, 윤봉길 등)", url: "https://biobrain.kr/face-recognition/" },
			// { title: "얼굴 방향 찾기(좌, 우, 상, 하)", url: "https://biobrain.kr/face-direction/" },
			{ title: "동영상 원격 PPG 기반 심박수 측정", url: "https://biobrain.kr/heartrate/" },
			{ title: "웹캠으로 사람을 인식, 다양한 필터를 사용할 수 있음", url: "https://biobrain.kr/selfie-filter/" },
			{ title: "웹캠에서 사람을 인식, 제거", url: "https://biobrain.kr/selfie/" },
			{ title: "웹캠으로 사람을 인식, 만화 필터 적용", url: "https://biobrain.kr/cartoon/" },
			{ title: "웹캠으로 시선추척", url: "https://biobrain.kr/gazer/" },
			{ title: "창문인가, 디스플레이인가 (MagicViewer)", url: "https://biobrain.kr/magicviewer/" },
			{ title: "Thy Body Landmarks to Thy Acupuncture Points", url: "https://biobrain.kr/acupoint/" },
			{ title: "바이오브레인 Web-SRS", url: "https://biobrain.kr/stt-tts/" },
			{ title: "InsideOut OutsideIn", url: "https://biobrain.kr/insideout/" },
		];

		const videoTabs = [
			{ url: "https://drive.google.com/file/d/1FBey37HJhZ2mcNMTGSPknjdKb5ClyXfk/preview", image: "/assets/images/video1.png" },
			{ url: "https://drive.google.com/file/d/1U7l_5HRluuF8vLhkUQFREPD4IXaSWK_j/preview", image: "/assets/images/video2.png" },
			{ url: "https://drive.google.com/file/d/1gFlEfTcq71OG1Qh07Ax2NF__crUGM_52/preview", image: "/assets/images/video3.png" },
		];

		return (
			<>
				<PC>
					<Box sx={{ height: "100%", backgroundColor: "black", paddingTop: 15 }} display="flex" justifyContent="center" marginBottom="150px">
						<Box height="50%" width="50%" display="flex" flexDirection="column">
							<Typography variant='h3' sx={{ marginY: 10, marginLeft: 10, color: 'white' }}>Artificial Intelligence</Typography>
							<Box
								display="flex"
								justifyContent="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{tabs.map((tab, index) => (
									<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none', whiteSpace: 'pre-wrap' }}>
										<TabBox>
											<Typography variant='subtitle1'>{tab.title}</Typography>
										</TabBox>
									</Link>
								))}
							</Box>
						</Box>
						<Box height="50%" width="50%" display="flex" flexDirection="column">
							<Box sx={{ marginTop: 10, marginBottom: 5 }}>
								<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Space Computing</Typography>
								<Typography variant='subtitle1' sx={{ paddingY: 1, marginLeft: 6, color: 'white' }}>공간 컴퓨팅 관련 준비 (Unreal Engine)</Typography>
							</Box>
							<Box
								display="flex"
								justifyContent="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{videoTabs.map((videoTab, index) => (
									<VideoTabBox
										key={index}
										bgImage={videoTab.image}
										onClick={() => this.handleDialogOpen(videoTab.url)} // Ensure correct URL is passed
									/>
								))}
							</Box>
						</Box>
						<Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose} maxWidth="lg">
							<DialogContent>
								<iframe src={this.state.videoUrl} width="600px" height="400px" allow="autoplay"></iframe>
							</DialogContent>
						</Dialog>
					</Box>
					<Footer />
				</PC >
				<TABLET>
					<Box sx={{ width: "100%", height: "100%", backgroundColor: "black", marginBottom: "100px" }} >
						<Box data-aos="fade-up" data-aos-delay="500" data-aos-duration="900" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<Box height="100%" paddingTop="150px">
								<Typography variant='h3' sx={{ marginY: 5, color: 'white' }}>Artificial Intelligence</Typography>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									flexWrap="wrap"
									gap={1}
									data-aos="fade-up"
									data-aos-delay="500"
									data-aos-duration="900"
									paddingBottom={5}
								>
									{tabs.map((tab, index) => (
										<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none' }}>
											<MobileTabBox>
												<Typography variant='subtitle2' sx={{ textTransform: "none" }}>{tab.title}</Typography>
											</MobileTabBox>
										</Link>
									))}
								</Box>
							</Box>
							<Box height="100%" marginBottom="200px">
								<Box sx={{ marginTop: 10, marginBottom: 5 }}>
									<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Space Computing</Typography>
									<Typography variant='subtitle1' sx={{ paddingY: 1, marginLeft: 6, color: 'white' }}>공간 컴퓨팅 관련 준비 (Unreal Engine)</Typography>
								</Box>
								<Box
									display="flex"
									justifyContent="center"
									flexWrap="wrap"
									gap={1}
									data-aos="fade-up"
									data-aos-delay="500"
									data-aos-duration="900"
								>
									{videoTabs.map((videoTab, index) => (
										<VideoTabBox
											key={index}
											bgImage={videoTab.image}
											onClick={() => this.handleDialogOpen(videoTab.url)} // Ensure correct URL is passed
										/>
									))}
								</Box>
								<Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose} maxWidth="lg">
									<DialogContent>
										<iframe src={this.state.videoUrl} width="550px" height="400px" allow="autoplay"></iframe>
									</DialogContent>
								</Dialog>
							</Box>
						</Box>
					</Box >
					<Footer />
				</TABLET>
				<MOBILE>
					<Box sx={{ width: "100%", height: "100%", backgroundColor: "black", marginY: "100px" }} >
						<Box data-aos="fade-up" data-aos-delay="500" data-aos-duration="900" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<Typography variant='h3' sx={{ marginY: 5, color: 'white' }}>Artificial Intelligence</Typography>
							<Box
								height="50%"
								display="flex"
								justifyContent="center"
								alignItems="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{tabs.map((tab, index) => (
									<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none' }}>
										<MobileTabBox>
											<Typography variant='subtitle2' sx={{ textTransform: "none" }}>{tab.title}</Typography>
										</MobileTabBox>
									</Link>
								))}
							</Box>
							<Box sx={{ marginTop: 20, marginBottom: 5 }}>
								<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Space Computing</Typography>
								<Typography variant='subtitle1' sx={{ paddingY: 1, marginLeft: 6, color: 'white' }}>공간 컴퓨팅 관련 준비 (Unreal Engine)</Typography>
							</Box>
							<Box
								display="flex"
								justifyContent="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{videoTabs.map((videoTab, index) => (
									<VideoTabBox
										key={index}
										bgImage={videoTab.image}
										onClick={() => this.handleDialogOpen(videoTab.url)} // Ensure correct URL is passed
									/>
								))}
							</Box>
							<Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose} maxWidth="lg">
								<DialogContent>
									<iframe src={this.state.videoUrl} width="300px" height="200px" allow="autoplay"></iframe>
								</DialogContent>
							</Dialog>
						</Box>
					</Box >
					<Footer />
				</MOBILE >
			</>
		);
	}
};

export default Ai;