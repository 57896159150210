import React, { Component } from 'react';
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const LOGO_WIDTH = 200;
const LOGO_HEIGHT = 59;

const MenuButtonStyle = styled(Button)({
	width: 130,
	fontSize: 17,
	fontWeight: 600,
	textTransform: "none",
	color: 'white',
	'&:hover': {
		backgroundColor: "transparent",
		color: "#00a0b9", // Change text color on hover
	},
});

const MenuSubButton = styled(Button)({
	display: "flex",
	justifyContent: "flex-start",
	color: 'white',
	'&:hover': {
		backgroundColor: "transparent",
		color: '#00a0b9', // Change text color on hover
	},
	textTransform: "none",
	width: 150, // 추가된 속성
	fontSize: 14, // 추가된 속성
	fontWeight: 600 // 추가된 속성
});

const MenuIconButton = styled(Button)({
	display: "flex",
	justifyContent: "flex-end",
	color: 'white',
	textTransform: "none",
	padding: "0px",
	width: "200px",
	marginLeft: 5,
	fontSize: 17,
	fontWeight: 600
});

const MoMenuIconButton = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
});

class ResponsiveAppBar extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			anchorElNav: null,
			anchorElUser: null,
			isHovered: false,
			expanded: false,
		};

		AOS.init();
	}

	componentDidMount()
	{
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (event) =>
	{
		if (this.node && !this.node.contains(event.target))
		{
			this.setState({ expanded: false });
		}
	};

	toggleExpanded = () =>
	{
		this.setState(prevState => ({
			expanded: !prevState.expanded
		}));
	};

	handleInteraction = () =>
	{
		this.toggleExpanded();
	};

	handleOpenNavMenu = (event) =>
	{
		this.setState({ anchorElNav: event.currentTarget });
	};

	handleOpenUserMenu = (event) =>
	{
		this.setState({ anchorElUser: event.currentTarget });
	};

	handleCloseNavMenu = () =>
	{
		this.setState({ anchorElNav: null });
	};

	handleCloseUserMenu = () =>
	{
		this.setState({ anchorElUser: null });
	};

	handleMouseEnter = () =>
	{
		this.setState({ isHovered: true });
		setTimeout(() =>
		{
			this.setState({ expanded: true });
		}, 100);
	};

	handleMouseLeave = () =>
	{
		this.setState({ isHovered: false });
		setTimeout(() =>
		{
			this.setState({ expanded: false });
		}, 150);
	};

	handleIconButtonClick = () =>
	{
		this.handleMouseEnter();
	};


	handleOpenModal = () =>
	{
		this.setState({ isModalOpen: true });
	};

	handleCloseModal = () =>
	{
		this.setState({ isModalOpen: false });
	};

	render()
	{
		const { expanded } = this.state;

		const appBarStyle = {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			zIndex: 9999,
			height: expanded ? `${290}px` : `${LOGO_HEIGHT + 16
				}px`,
			transition: 'height 0.7s',
			backgroundColor: "rgba(22, 22, 22, 0.9)",
		};

		return (
			<AppBar position="static" style={appBarStyle} >
				<Box width="100vw" ref={node => { this.node = node; }}>
					<Toolbar disableGutters position="relative">
						<Box sx={{
							mt: 1,
							ml: 5,
							display: { xs: 'none', sm: "none", md: 'flex' },
						}}>
							<Link to="/#">
								<Box component="img" sx={{ width: `${LOGO_WIDTH}px`, position: "absolute", top: 8, left: 8 }} src={process.env.PUBLIC_URL + '/assets/images/logo_name_white.png'}></Box>
							</Link>
						</Box>
						<Box width="100%" display="flex" justifyContent="flex-start" textAlign="center">
							<Link to="/#">
								<Box
									component="img"
									pt={1}
									pl={2}
									display="flex"
									justifyContent="flex-start"
									sx={{
										width: "54px",
										height: "43px",
										display: { xs: 'flex', sm: "flex", md: 'none' },
										flexGrow: 1,
										// 
									}}
									src={process.env.PUBLIC_URL + '/assets/images/logo.png'}
								>
								</Box>
							</Link>
						</Box>
						<Box mt={1} pr={2} sx={{ display: { xs: 'flex', sm: "flex", md: 'none' } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={this.handleInteraction} // 마우스 클릭과 터치 이벤트 모두 처리
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
						</Box>
						<Box display="flex" justifyContent="flex-end" sx={{ position: "absolute", top: 15, right: "3%", width: "700px", display: { xs: 'none', md: 'flex' } }} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} >
							<Box display="column">
								<Box mx={2} display="flex">
									<Link to="/companypage" style={{ textDecoration: 'none' }}>
										<MenuButtonStyle>Company</MenuButtonStyle>
									</Link>
									<Link to="/productpage" style={{ textDecoration: 'none' }}>
										<MenuButtonStyle>Product</MenuButtonStyle>
									</Link>
									<Link to="/businesspage" style={{ textDecoration: 'none' }}>
										<MenuButtonStyle>Business</MenuButtonStyle>
									</Link>
									<Link to="/researchpage" style={{ textDecoration: 'none' }}>
										<MenuButtonStyle>Research</MenuButtonStyle>
									</Link>
									<Box mt={1.5} mx={2} >|</Box>
									<Link to="/ai" style={{ textDecoration: 'none' }}>
										<MenuButtonStyle width="250px">PlayGround</MenuButtonStyle>
									</Link>
									<Link to="/bioscanpage" style={{ textDecoration: 'none' }}>
										<MenuButtonStyle>BioScan</MenuButtonStyle>
									</Link>
								</Box>
								<Box display="column">
									{expanded && (
										<Box
											data-aos="fade-up"
											data-aos-delay="700"
											data-aos-duration="900"
											display='flex'
											justifyContent="flex-end"
											sx={{
												backgroundColor: 'rgba(52, 52, 52, 0)',
												color: 'white',
												paddingTop: '15px',
												gap: '15px',
												display: { xs: 'none', md: 'flex' },
											}}
										>
											<Box sx={{ width: "140px", display: "column" }}>
												<MenuSubButton href="http://biobrain.kr/bioscan-hrv">BioScan-HRV</MenuSubButton>
												<MenuSubButton href="http://biobrain.kr/bioscan-st">BioScan-ST</MenuSubButton>
												<MenuSubButton href="http://biobrain.kr/bioscan-topomap">BioScan-TopoMap</MenuSubButton>
												<MenuSubButton href="http://biobrain.kr/bioscan-spectrum">BioScan-Spectrum</MenuSubButton>
												<MenuSubButton href="https://biobrain.kr/bioscan-mobile/">BioScan-Mobile</MenuSubButton>
												<MenuSubButton href="https://biobrain.kr/bioscan-micro/">BioScan-micro</MenuSubButton>
											</Box>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Toolbar>
					{this.state.expanded && (
						<Box
							data-aos="fade-up"
							data-aos-delay="700"
							data-aos-duration="900"
							display='flex'
							justifyContent="flex-end"
							sx={{
								top: '100%',
								left: 0,
								right: 0,
								backgroundColor: 'rgba(52, 52, 52, 0)',
								color: 'white',
								padding: '8px',
								gap: '4px',
								display: { xs: 'flex', md: 'none' },
							}}
						>
							<Box sx={{ marginLeft: 1, width: "150px", display: "column" }}>
								<Link to="/companypage" >
									<MoMenuIconButton>
										<MenuIconButton>Company</MenuIconButton>
									</MoMenuIconButton>
								</Link>
								<Link to="/productpage" >
									<MoMenuIconButton>
										<MenuIconButton>Product</MenuIconButton>
									</MoMenuIconButton>
								</Link>
								<Link to="/businesspage">
									<MoMenuIconButton>
										<MenuIconButton>Business</MenuIconButton>
									</MoMenuIconButton>
								</Link>
								<Link to="/researchpage" >
									<MoMenuIconButton>
										<MenuIconButton>Research</MenuIconButton>
									</MoMenuIconButton>
								</Link>
								<Link to="/ai">
									<MoMenuIconButton>
										<MenuIconButton>PlayGround</MenuIconButton>
									</MoMenuIconButton>
								</Link>
								<Link to="/bioscanpage">
									<MoMenuIconButton>
										<MenuIconButton>Bioscan</MenuIconButton>
									</MoMenuIconButton>
								</Link>
							</Box>
						</Box>
					)}
				</Box>
			</AppBar>
		);
	}
}

export default ResponsiveAppBar;

