import React from 'react';
import { MOBILE, TABLET, PC } from "./global";
import "../App.css";

import { Button, IconButton, Typography, Box, Grid } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';

export default function AutoGrid()
{
    const openLinkNewTab = () =>
    {
        window.open("https://map.naver.com/p/search/%EB%B0%94%EC%9D%B4%EC%98%A4%EB%B8%8C%EB%A0%88%EC%9D%B8?c=15.00,0,0,0,dh", "_blank", "noopener,noreferrer");
    };

    const openLinkNewTabTwo = () =>
    {
        window.open("https://map.naver.com/p/entry/place/1988286109?c=16.48,0,0,0,dh", "_blank", "noopener,noreferrer");
    };

    const openLinkFacebook = () =>
    {
        window.open("https://www.facebook.com/BIOBRAININC/", "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <PC>
                <Box pl={5} sx={{ height: "100%", flexGrow: 1, backgroundColor: "#282828", paddingTop: "50px", paddingBottom: "20px" }}>
                    <Grid container spacing={1} sx={{ width: "100%" }}>
                        <Grid xs sx={{ display: "flex", justifyContent: "center" }}>
                            <Box display="flex" flexDirection="column">
                                <Box p={1} display="flex" alignItems="center">
                                    <IconButton sx={{ padding: "0px" }}>
                                        <LocationOnIcon size={'1.5rem'} sx={{ color: "lightgray", }} />
                                    </IconButton>
                                    <Typography variant="h6" sx={{ color: "white", fontWeight: "blod" }}
                                    >본사</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" sx={{ color: "white" }}>
                                    <Typography variant="body2"
                                    >대전광역시 서구 둔산대로117번길 66 골드벤처타워 208호</Typography>
                                    <Button onClick={() => openLinkNewTab()}>지도</Button>
                                    {/* <Button onClick={() => handleClickOpen()} size="small">지도</Button> */}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs sx={{ display: "flex", justifyContent: "center" }}>
                            <Box pl={5} display="flex" flexDirection="column">
                                <Box p={1} display="flex" alignItems="center">
                                    <IconButton sx={{ padding: "0px" }}>
                                        <LocationOnIcon size={'1.5rem'} sx={{ color: "lightgray" }} />
                                    </IconButton>
                                    <Typography variant="h6" sx={{ color: "white", fontWeight: "blod" }}
                                    >연구소</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" sx={{ color: "white" }}>
                                    <Typography variant="body2"
                                    >대전광역시 유성구 테크노4로 대덕비즈센터 B207</Typography>
                                    <Button onClick={() => openLinkNewTabTwo()}>지도</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs sx={{ display: "flex", justifyContent: "center" }}>
                            {/* <Item>xs</Item> */}
                            <Box pt={2} display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center" sx={{ color: "white" }}>
                                    {/* <IconButton sx={{ paddingY: "0px" }}> */}
                                    <CallIcon size={'1.5rem'} sx={{ marginX: "8px", color: "lightgray" }} />
                                    {/* </IconButton> */}
                                    <Typography variant="body2">010-3917-4428</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" sx={{ color: "white" }}>
                                    {/* <IconButton sx={{ paddingY: "0px" }}> */}
                                    <EmailIcon size={'1.5rem'} sx={{ marginX: "8px", color: "lightgray" }} />
                                    {/* </IconButton> */}
                                    <Typography variant="body2">ks@biobrain.kr</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", color: "white" }}>
                                    <IconButton onClick={() => openLinkFacebook()} sx={{}}>
                                        <FacebookIcon
                                            size={'1.5rem'}
                                            sx={{
                                                color: "lightgray",
                                                '&:hover': {
                                                    backgroundColor: "#3b5a9a",
                                                },
                                            }} />
                                    </IconButton >
                                    <IconButton sx={{}}><InstagramIcon size={'1.5rem'} sx={{
                                        color: "lightgray", '&:hover': {
                                            background: "radial-gradient(circle farthest-corner at 32% 106%,#ffe17d 0%,#ffcd69 10%,#fa9137 28%,#eb4141 42%,transparent 82%) , linear-gradient(135deg,#234bd7 12%,#c33cbe 58%)"
                                        },
                                    }} /></IconButton >
                                    <IconButton sx={{}}><YouTubeIcon size={'1.5rem'} sx={{
                                        color: "lightgray", '&:hover': {
                                            backgroundColor: "#ff001d",
                                        },
                                    }} /></IconButton >
                                    <IconButton sx={{}}><GitHubIcon size={'1.5rem'} sx={{
                                        color: "lightgray", '&:hover': {
                                            backgroundColor: "#000",
                                        },
                                    }} /></IconButton >
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" paddingY={1}>
                        <Typography variant="caption" sx={{ color: "white", fontWeight: "bold" }}>Copyright 2024. BioBrain Corp. all rights reserved.</Typography>
                    </Box>
                </Box>
            </PC>
            <TABLET>
                <Box pl={5} display="flex" flexDirection="column" sx={{ height: "100%", flexGrow: 1, backgroundColor: "#282828", paddingBottom: "20px" }}>
                    <Grid container spacing={0.5} sx={{ width: "100%", flexDirection: 'column' }}>
                        <Grid xs sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <Box m={1} display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center">
                                    <IconButton sx={{ padding: "0px" }}>
                                        <LocationOnIcon size={'1rem'} sx={{ color: "lightgray", }} />
                                    </IconButton>
                                    <Typography variant="h6" sx={{ color: "white", fontWeight: "blod" }}
                                    >본사</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" sx={{ color: "white" }}
                                    >대전광역시 서구 둔산대로117번길 66 골드벤처타워 208호</Typography>
                                    <Box>
                                        <Button onClick={() => openLinkNewTab()}>지도</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <Box m={1} display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center">
                                    <IconButton sx={{ padding: "0px" }}>
                                        <LocationOnIcon size={'1rem'} sx={{ color: "lightgray" }} />
                                    </IconButton>
                                    <Typography variant="h6" sx={{ color: "white", fontWeight: "blod" }}
                                    >연구소</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" sx={{ color: "white" }}
                                    >대전광역시 유성구 테크노4로 대덕비즈센터 B207</Typography>
                                    <Box>
                                        <Button onClick={() => openLinkNewTabTwo()}>지도</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <Box mt={1} display="flex" flexDirection="column">
                                <Box ml={1} display="flex" alignItems="center" sx={{ color: "white" }}>
                                    <IconButton sx={{ padding: "0px" }}>
                                        <CallIcon size={'1rem'} sx={{ color: "lightgray" }} />
                                    </IconButton>
                                    <Typography variant="body2">010-3917-4428</Typography>
                                </Box>
                                <Box ml={1} display="flex" alignItems="center" sx={{ color: "white" }}>
                                    <IconButton sx={{ padding: "0px" }}>
                                        <EmailIcon size={'1rem'} sx={{ color: "lightgray" }} />
                                    </IconButton>
                                    <Typography variant="body2">ks@biobrain.kr</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", color: "white" }}>
                                    <IconButton onClick={() => openLinkFacebook()} sx={{ paddingRight: "0px" }}>
                                        <FacebookIcon
                                            size={'1.5rem'}
                                            sx={{
                                                color: "lightgray",
                                                '&:hover': {
                                                    backgroundColor: "#3b5a9a",
                                                },
                                            }} />
                                    </IconButton >
                                    <IconButton sx={{ paddingRight: "0px" }}><InstagramIcon size={'1.5rem'} sx={{
                                        color: "lightgray", '&:hover': {
                                            background: "radial-gradient(circle farthest-corner at 32% 106%,#ffe17d 0%,#ffcd69 10%,#fa9137 28%,#eb4141 42%,transparent 82%) , linear-gradient(135deg,#234bd7 12%,#c33cbe 58%)"
                                        },
                                    }} /></IconButton >
                                    <IconButton sx={{ paddingRight: "0px" }}><YouTubeIcon size={'1.5rem'} sx={{
                                        color: "lightgray", '&:hover': {
                                            backgroundColor: "#ff001d",
                                        },
                                    }} /></IconButton >
                                    <IconButton sx={{ paddingRight: "0px" }}><GitHubIcon size={'1.5rem'} sx={{
                                        color: "lightgray", '&:hover': {
                                            backgroundColor: "#000",
                                        },
                                    }} /></IconButton >
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box m={1} display="flex" justifyContent="flex-start">
                        <Typography variant="caption" sx={{ color: "white" }}>Copyright 2024. BioBrain Corp. all rights reserved.</Typography>
                    </Box>
                </Box>
            </TABLET>
            <MOBILE>
                <Box sx={{ width: "100%", height: "100%"}}>
                    <Box display="flex" flexDirection="column" sx={{flexGrow: 1, backgroundColor: "#282828", paddingBottom: "60px" }}>
                        <Grid container spacing={0.5} sx={{ width: "100%", height: "100%", flexDirection: 'column' }}>
                            <Grid xs sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box m={1} display="flex" flexDirection="column">
                                    <Box display="flex" alignItems="center">
                                        <IconButton sx={{ padding: "0px" }}>
                                            <LocationOnIcon size={'1rem'} sx={{ color: "lightgray", }} />
                                        </IconButton>
                                        <Typography variant="h6" sx={{ color: "white", fontWeight: "blod" }}
                                        >본사</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body2" sx={{ color: "white" }}
                                        >대전광역시 서구 둔산대로117번길 66 골드벤처타워 208호</Typography>
                                        <Box>
                                            <Button onClick={() => openLinkNewTab()}>지도</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box m={1} display="flex" flexDirection="column">
                                    <Box display="flex" alignItems="center">
                                        <IconButton sx={{ padding: "0px" }}>
                                            <LocationOnIcon size={'1rem'} sx={{ color: "lightgray" }} />
                                        </IconButton>
                                        <Typography variant="h6" sx={{ color: "white", fontWeight: "blod" }}
                                        >연구소</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body2" sx={{ color: "white" }}
                                        >대전광역시 유성구 테크노4로 대덕비즈센터 B207</Typography>
                                        <Box>
                                            <Button onClick={() => openLinkNewTabTwo()}>지도</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box display="flex" flexDirection="column">
                                    <Box ml={1} display="flex" alignItems="center" sx={{ color: "white" }}>
                                        <IconButton sx={{ padding: "0px" }}>
                                            <CallIcon size={'1rem'} sx={{ color: "lightgray" }} />
                                        </IconButton>
                                        <Typography variant="body2">010-3917-4428</Typography>
                                    </Box>
                                    <Box ml={1} display="flex" alignItems="center" sx={{ color: "white" }}>
                                        <IconButton sx={{ padding: "0px" }}>
                                            <EmailIcon size={'1rem'} sx={{ color: "lightgray" }} />
                                        </IconButton>
                                        <Typography variant="body2">ks@biobrain.kr</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", color: "white" }}>
                                        <IconButton onClick={() => openLinkFacebook()} sx={{ paddingRight: "0px" }}>
                                            <FacebookIcon
                                                size={'1.5rem'}
                                                sx={{
                                                    color: "lightgray",
                                                    '&:hover': {
                                                        backgroundColor: "#3b5a9a",
                                                    },
                                                }} />
                                        </IconButton >
                                        <IconButton sx={{ paddingRight: "0px" }}><InstagramIcon size={'1.5rem'} sx={{
                                            color: "lightgray", '&:hover': {
                                                background: "radial-gradient(circle farthest-corner at 32% 106%,#ffe17d 0%,#ffcd69 10%,#fa9137 28%,#eb4141 42%,transparent 82%) , linear-gradient(135deg,#234bd7 12%,#c33cbe 58%)"
                                            },
                                        }} /></IconButton>
                                        <IconButton sx={{ paddingRight: "0px" }}><YouTubeIcon size={'1.5rem'} sx={{
                                            color: "lightgray", '&:hover': {
                                                backgroundColor: "#ff001d",
                                            },
                                        }} /></IconButton>
                                        <IconButton sx={{ paddingRight: "0px" }}><GitHubIcon size={'1.5rem'} sx={{
                                            color: "lightgray", '&:hover': {
                                                backgroundColor: "#000",
                                            },
                                        }} /></IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box ml={1} display="flex" justifyContent="">
                            <Typography variant="caption" sx={{ color: "white" }}>Copyright 2024. BioBrain Corp. all rights reserved.</Typography>
                        </Box>
                    </Box>
                </Box>
            </MOBILE>
        </>
    );
}